import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: "https://api-gateway.skymavis.com/graphql/mavis-marketplace",
});

const authLink = setContext((_, { headers }) => {
    const apiKey = 'nne7AFVO0Ccwona19t2hHT4HXAsEGt6Q'; // Replace with your actual API key
    return {
        headers: {
            ...headers,
            'X-API-Key': apiKey,
        },
    };
});

const link = authLink.concat(httpLink) as ApolloLink;

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
