import React from "react";
import logo from "./logo.svg";
import "./assets/style.css";
import "./assets/fontawesome/css/all.min.css";
import "./App.css";
import Home from "./components/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const App: React.FC = () => {
    return (
        <>
            
                <CssBaseline />
                <Router>
                    <Routes>
                        <Route path="/" element={<Home />} />
                    </Routes>
                </Router>                
        </>
    );
};

export default App;
